.reflection-pointer {
  cursor: pointer !important;
}

.reflection-pointer-default {
  cursor: default !important;
}

.bg-secondary {
  background-color: #f4f5f7 !important;
}

.reflection-bg-secondary {
  background-color: #f7fafc;
}

.reflection-select-blured {
  color: #adb5bd;
}

.reflection-select-focused {
  color: #8b8e8f;
}

.reflection-center-row td {
  vertical-align: middle !important;
}

.reflection-text-success{
  color:#239e69
}

/* .pac-container:after {
  content: none !important;
} */

.carousel-inner {
  height: 400px !important;
}

.carousel-control-prev {
  left: 0% !important;
  top: 0 !important;
}

.carousel-control-next {
  left: 100% !important;
  top: 0 !important;
}

.carousel-card {
  width: 350px;
  height: 280px;
}

.carousel-parent {
  display: flex;
}

.wirepackage-nav-link {
  font-weight: bold !important;
}

.wirepackage-nav-link:hover,
.wirepackage-nav-link.active {
  color: #5e72e4 !important;
}

.wirepackage-nav-link:hover {
  cursor: pointer;
}

.wirepackage-display-1 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.3;
}

.wirepackage-display-2 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.3;
}

.wirepackage-display-3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
}

.wirepackage-display-4 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.wirepackage-card-body {
  padding: 20px 40px 20px !important;
}

.card-project .card-body {
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
  padding 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding: 30px 40px 30px !important;
  position: relative;
  overflow: hidden;
}

.card-project {
  min-height: 250px !important;
}

.wirepackage-banner {
  background-color: #ffcb3d;
  position: relative;
  z-index: 100;
}

/*added for base css*/
.headroom--not-top {
  top: 0;
}

.wirepackage-page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: 0;
  /* opacity: 0.4; */
}

.wirepackage-navbar-padding {
  padding: 10px 0 !important;
}

.wirepackage-transparent-03 {
  opacity: 0.3 !important;
}

.wirepackage-text-gold {
  color: rgb(226, 155, 47);
}

.wirepackage-text-bright-gold
{
  color: #FDD017;
}

.wirepackage-bg-mustard {
  background-color: #ffcb3d !important;
}

.wirepackage-bg-sky {
  background-color: rgb(123, 222, 255);
}

.wirepackage-text-success {
  color: #239e69;
}

.wirepackage-text-table {
  color: #525f7f;
}

.wirepackage-bg-success {
  background-color: #239e69 !important;
}

.wirepackage-bg-success-light {
  background-color: #c4f1de !important;
}

.wirepackage-d-none {
  visibility: hidden;
}

.wirepackage-btn-mustard {
  color: #fff;
  background-color: #ffcb3d;
  border-color: #ffcb3d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.wirepackage-btn-mustard:hover {
  color: #fff;
  background-color: #ffcb3d;
  border-color: #ffcb3d;
}

.wirepackage-btn-mustard:focus,
.wirepackage-btn-mustard.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
  0 0 0 0 rgba(255, 203, 61, 0.5);
}

.wirepackage-btn-mustard.disabled,
.wirepackage-btn-mustard:disabled {
  color: #fff;
  background-color: #ffcb3d;
  border-color: #ffcb3d;
}

.wirepackage-btn-mustard:not(:disabled):not(.disabled):active,
.wirepackage-btn-mustard:not(:disabled):not(.disabled).active,
.show > .wirepackage-btn-mustard.dropdown-toggle {
  color: #fff;
  background-color: #ffcb3d;
  border-color: #ffcb3d;
}

.wirepackage-btn-mustard:not(:disabled):not(.disabled):active:focus,
.wirepackage-btn-mustard:not(:disabled):not(.disabled).active:focus,
.show > .wirepackage-btn-mustard.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(255, 203, 61, 0.5);
}

/* .navbar-nav.navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
} */

.wirepackage-vertical-scroll {
    overflow-x: hidden;
    overflow-y: auto;
}

.time-line-ctnr .time-line:before {
    background-color: #adb5bd !important;
}

.time-line-ctnr .time-line > li > .fa {
    border-color: #adb5bd !important;
}

.custom-switch .custom-control-label::before {
  background-color: #8b8e8f;
  height: 24px;
  width: 35px !important;
}

/* .custom-switch .custom-control-input:checked ~ .custom-control-label::after  */
.custom-switch .custom-control-label::after {
  height: 20px !important;
  width: 20px !important;
}

.bg-primary-shade {
  background-color: #dfe3fa !important;
}

.wirepackage-bg-gradient-primary {
  background: linear-gradient(180deg, #5e72e4 0, #2dce89 100%) !important;
}

.container-fluid {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.wirepackage-label {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.wirepackage-bg-green
{
 background: #229967;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label-or {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label-or .react-switch-button-or {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox-or:checked + .react-switch-label-or .react-switch-button-or {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label-or:active .react-switch-button-or {
  width: 60px;
}


/* small switch */
.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 50px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 30px;
}

.wirepackage-bagde-primary {
  color: #2643e9 !important; 
  background-color: #eaecfb;
}

.wirepackage-icon{
  cursor: pointer;
}

.wirepackage-icon:active {
  color: #5e72e4 !important; 
}
